.formGroup {
  flex-grow: 1;

  .formLabel {
    align-items: center;
    display: flex;
    gap: 0.35rem;

    small {
      background-color: rgb(232, 232, 232);
      border-radius: 10px;
      padding: 0 0.5rem;
    }
  }
}
